<template>
	<div>
		<el-dialog :title="title" :visible.sync="weiCodeIshow" width="500px" @closed="comeback">
			<div v-loading="codeding">
				<div class = "flexRow">
					<div class = "rightfont">小程序路径：</div>
					<el-input v-model="InputvalueNew" style="flex:1" disabled></el-input>
					<el-button v-clipboard:copy="InputvalueNew" v-clipboard:success="onCopy" v-clipboard:error="onError">
						复制
					</el-button>
				</div>
				<div class = "flexRow" style="margin-top:20px">
					<div class = "rightfont">短信链接：</div>
					<el-input v-model="smslink" style="flex:1" disabled></el-input>
					<el-button v-clipboard:copy="smslink" v-clipboard:success="onCopy" v-clipboard:error="onError">
						复制
					</el-button>
				</div>
				<img :src="imgUrl+codePicture" alt="" id="codeId"
					style="width:300px;display: block;margin: 30px auto 0">
				<div style="text-align:center;margin-top: 25px;">
					<span style="color:#409EFF;cursor:pointer;padding:20px 0;display: inline-block; "
						@click="downloadIamge('img',codePicture)">{{downFont}}</span>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import apiList from '@/api/other';
	import config from '@/config/index'
	import axios from "axios"
	import {
		mallFreeVersionConfigInfo
	} from "@/api/goods"
	import {
		wxappwxappgenerateurllink
	} from "@/api/sv3.0.0"
	export default {
		props: {
			weiCodeData: {
				type: Object
			},
			Num: {
				type: Number,
			},
			downFont:{
				type:String,
				default:'下载小程序码'
			},
			title: {
				type: String,
				default: () => {
					return '微信小程序'
				}
			},
			weiCodeVisivble: {
				type: Boolean,
				default: () => {
					return false
				}
			}

		},
		created() {
			this.weiCodeIshow = this.weiCodeVisivble
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				goUrls: config.GO_URL,
				codePicture: 'https://cdn.dkycn.cn/melyshop/image/20191222-492d658e-4654-4778-a097-9e439e9de307.png',
				inputValue: '',
				weiCodeIshow: false,
				codeding: false,
				InputvalueNew: '',
				mallConfigInfo:{},

				smslink:'',
			}
		},
		watch: {
			weiCodeVisivble(n, o) {
				this.weiCodeIshow = n
			},
			weiCodeData(n, o) {
				this.inputValue = this.weiCodeData.Scene == 1 ? `${this.weiCodeData.Page}` :
					`${this.weiCodeData.Page}?${this.weiCodeData.Scene}`
					// console.log(this.inputValue)
				// if(!this.weiCodeData.Scene){
				// 	this.inputValue = this.weiCodeData.Page
				// }
				this.InputvalueNew = this.weiCodeData.InputVaule ? this.weiCodeData.InputVaule : this.inputValue
				// this.inputValue =this.weiCodeData.Scene?`${this.weiCodeData.Page}?${this.weiCodeData.Scene}`:`${this.weiCodeData.Page}`
				// console.log(this.weiCodeData.Scene)
				this.getData()

			},
		},
		methods: {
			async getMallConfig() {
				let res = await mallFreeVersionConfigInfo()
				this.mallConfigInfo = res.Result
					// console.log(this.mallConfigInfo)
					window.open(this.mallConfigInfo.WxAppAuthUrl)
			},
			getRamNumber() {
				var result = '';
				for (var i = 0; i < 16; i++) {
					result += Math.floor(Math.random() * 16).toString(16);
				}
				return result.toUpperCase();
			},
			async getData() {
				try {
					this.codeding = true
					this.weiCodeIshow = false
					let data = {
						Page: this.weiCodeData.Page,
						Scene: this.weiCodeData.Scene,
						IsLimited: false,
						ProgramType: 0,
						noError: true
					}
					// if(!this.weiCodeData.Scene){
					// 	data.IsLimited = true
					// }
					// console.log(data)
					let result = await apiList.mallGetQrCode(data)
					// console.log(result, 'getData')
					// result.ErrorCode = 85074
					// console.log(result.ErrorCode)
					if (result.ErrorCode == 0) {
						this.weiCodeIshow = true
						this.codePicture = JSON.parse(result.Result)[0]
						console.log()
					} else {
						this.weiCodeIshow = false
						if (result.ErrorCode == 1000063) { //未绑定商城小程序
							this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
								this.signLoding = false;
								this.state = window.localStorage.getItem('mlmzFreePayState')
								// this.state = 0
								// console.log(window.localStorage.getItem('mlmzFreePayState'))
								if (this.state == 0) {
									this.$confirm('你的商城还未授权绑定微信小程序，商城页面链接生成失败，请先完成小程序绑定', '提示', {
										distinguishCancelAndClose: true,
										confirmButtonText: '去绑定',
										cancelButtonText: '关闭'
									}).then(() => {
										this.getMallConfig()
									}).catch(action => {});
									return
								}
								window.localStorage.setItem('canEditSetinfos', true);
								window.localStorage.setItem('toPayResultFromPath', this.$route.path);
							}).catch(() => {})
						} else if (result.ErrorCode == 85074) { //小程序未发布
							this.$confirm('商城小程序还未发布上线，推广码生成失败', '', {
								confirmButtonText: '去发布',
								cancelButtonText: '关闭',
							}).then(() => {
								let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
								let url = this.goUrls + headsUrls + '/setting/wxAppManage'
								window.open(url)
							}).catch(() => {})
						} else if (result.ErrorCode == 41030) { //page页面不存在
							this.$confirm('获取新页面推广码，请先将小程序版本更新成最新版本', '', {
								confirmButtonText: '去更新',
								cancelButtonText: '关闭',
							}).then(() => {
								let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
								let url = this.goUrls + headsUrls + '/setting/wxAppManage'
								window.open(url)
							}).catch(() => {})
						}else{
							this.$message.error(result.Message)
						}
					}

					let msg = {
						Path:this.weiCodeData.Page,
						query:this.weiCodeData.Scene?this.weiCodeData.Scene:''
					}
					//  console.log(msg.Path,1)
					//单独给多人拼团做判断
					if(this.weiCodeData.InputVaule){
						let num = this.weiCodeData.InputVaule.indexOf('?')
						let query = this.weiCodeData.InputVaule.substring(num+1,this.weiCodeData.InputVaule.length)
						// console.log(query)
						msg.query = query
					}


					let secondParam = window.localStorage.getItem('mlmzMallKey')
					let url = ''
					if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage') {
						url =`http://wapapi.qidian.shop/jump.html?path=${encodeURIComponent(msg.Path)}&query=${encodeURIComponent(msg.query)}&mallkey=` + secondParam
					} else {
						url =`http://wap.dkyapitest.cn/jump.html?path=${encodeURIComponent(msg.Path)}&query=${encodeURIComponent(msg.query)}&mallkey=` + secondParam
					}
					let res2 = await axios.post('http://api.xsmore.com:80/short/link/',{Url:url,R:true})
					if(res2.status==200){
						this.smslink = res2.data.Url
					}else{
						this.$message.error(res2.data.Message)
					}
					// let res = await wxappwxappgenerateurllink(msg)
					// if(res.IsSuccess){
					// 	this.smslink = res.Result.url_link
					// }
				} catch (e) {
					console.log('catch', e)
				} finally {
					this.codeding = false
				}
			},
			async jumplink() {
				let result = await mallFreeVersionConfigInfo()
				// console.log(result)
				return result
			},
			comeback() {
				this.$emit('cancel', false)
			},

			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});

			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},

			downloadIamge(selector, name) {
				var image = new Image()
				// 解决跨域 Canvas 污染问题 解决跨域问题-并不好使，需要改对应的请求服务器
				image.setAttribute('crossOrigin', 'anonymous')
				image.onload = function() {
					var canvas = document.createElement('canvas')
					canvas.width = image.width
					canvas.height = image.height

					var context = canvas.getContext('2d')
					context.drawImage(image, 0, 0, image.width, image.height)
					var url = canvas.toDataURL('image/png')

					// 生成一个a元素
					var a = document.createElement('a')
					// 创建一个单击事件
					var event = new MouseEvent('click')

					// 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
					a.download = name || '下载图片名称'
					// 将生成的URL设置为a.href属性
					a.href = url
					// 触发a的单击事件
					a.dispatchEvent(event)
				}

				image.src = document.getElementById('codeId').src


			},
		}
	}
</script>

<style lang="less">
	#btnsavaImg {
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
		display: inline-block;
		line-height: 1;
		white-space: nowrap;
		border: 0;
		cursor: pointer;
		-webkit-appearance: none;
		text-align: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		outline: 0;
		margin: 0;
		-webkit-transition: .1s;
		transition: .1s;
		font-weight: 500;
		padding: 9px 15px;
		font-size: 14px;
		border-radius: 4px;
		margin: 20px 0;
	}
	.flexRow{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.rightfont{
		width: 85px;
		text-align: right;
	}
</style>
