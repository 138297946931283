<template>
	<div class="buttonPermissions" v-if="isShow" :style="Type==1?'display:block':'display: inline-block'">
		<slot />
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'

	export default {
		props: {
			datas: {
				type: Array
			},
			Type:{
				type:Number
			}
		},
		computed: {
			...mapGetters([
				'routerList'
			])
		},
		data() {
			return {
				isShow: true,
				authorityBtn: '',
				IshideArray:[]
			}
		},
		beforeMount() {
			this.ishave()
		},
		watch: {
			datas(val) {
				this.ishave()
			},
		},
		methods: {
			isContainArr(parent, child) {
				return child.every(item => {//全是true返回true
					return parent.every(v => {//每一项都没有返回true
						return item != v
					})
				})
			},
			ishave() {
        
				// console.log(this.isContainArr(this.routerList,this.datas))
				this.isShow = !this.isContainArr(this.routerList,this.datas)
				// if (this.routerList.every(item => item == this.datas)) {
				// 	this.isShow = true
				// } else {
				// 	this.isShow = false
				// }
				this.$emit('isShow', 	this.isShow )
			}

		}
	}
</script>

<style>
</style>
