<template>
	<div class="buttonPermissions" v-if="isShow" :style="Type==1?'display:block':'display: inline-block'">
		<slot />
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'

	export default {
		props: {
			datas: {
				type: String
			},
			Type:{
				type:Number
			}
		},
		computed: {
			...mapGetters([
				'routerList'
			])
		},
		data() {
			return {
				isShow: true,
				authorityBtn: '',
				IshideArray:[]
			}
		},
		beforeMount() {
			this.ishave()
		},
		watch: {
			datas(val) {
				this.ishave()
			},
		},
		methods: {
			ishave() {
				if (this.routerList.some(item => item == this.datas)) {
					this.isShow = true
				} else {
					this.isShow = false
				}
				this.$emit('isShow', 	this.isShow )
			}

		}
	}
</script>

<style>
</style>
