<template>
	<el-popover trigger="hover" width="240" placement="bottom">
		<div class="tips">扫描下方二维码体验</div>
		<div style="width:150px;margin:0 auto 0px;padding-bottom: 20px;">
			<img :src="url" style="display: block;width: 100%;"></img>
		</div>
		<div class="help-link" v-if="docUrl">
			<el-link type="primary" :href="docUrl" target="_blank"><i class="el-icon-right"></i>查看功能体验说明</el-link>
		</div>
		
		<el-button type="text" class="code-btn" slot="reference">
			<svg t="1626336483888" class="icon" viewBox="0 0 1024 1024" version="1.1"
				xmlns="http://www.w3.org/2000/svg" p-id="2003" style="float:left" width="15" height="15">
				<path
					d="M357.6 960.8H254.8c-86.7 0-157.2-70.5-157.2-157.2V632c0-11 9-20 20-20s20 9 20 20v171.5c0 64.6 52.6 117.2 117.2 117.2h102.9c11 0 20 9 20 20s-9 20.1-20.1 20.1zM769.3 960.8H666.4c-11 0-20-9-20-20s9-20 20-20h102.9c64.6 0 117.2-52.6 117.2-117.2V632c0-11 9-20 20-20s20 9 20 20v171.5c-0.1 86.7-70.6 157.3-157.2 157.3zM906.4 412c-11 0-20-9-20-20V220.5c0-64.6-52.6-117.2-117.2-117.2H666.4c-11 0-20-9-20-20s9-20 20-20h102.9c86.7 0 157.2 70.5 157.2 157.2V392c-0.1 11-9 20-20.1 20zM117.6 412c-11 0-20-9-20-20V220.5c0-86.7 70.5-157.2 157.2-157.2h102.9c11 0 20 9 20 20s-9 20-20 20H254.8c-64.6 0-117.2 52.6-117.2 117.2V392c0 11-9 20-20 20zM957.9 532H66.1c-11 0-20-9-20-20s9-20 20-20h891.8c11 0 20 9 20 20s-9 20-20 20z"
					p-id="2004"></path>
			</svg>
			<div style="float:left;margin-left:5px;">功能体验码</div>
		</el-button>
	</el-popover>
</template>

<script>
	export default {
		components: {
		},
		props: {
			docUrl: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				url: ''
			}
		},
		mounted() {
			this.url = `https://cdn.dkycn.cn/images/melyshop/code/${this.$route.name}.png`;
		},
		watch: {

		},
		methods: {
		}
	}
</script>

<style lang="less" scoped>
	
	.tips{
		font-size: 14px;
		color: #999;
		text-align: center;
		margin-bottom: 20px;
		padding-top: 10px;
	}
	
	.code-btn{
		color: #666;
		svg{
			fill: #666666;
		}
	}
	.code-btn:hover{
		color: #3a8ee6;
		svg{
			fill: #3a8ee6;
		}
	}
	.help-link{
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
