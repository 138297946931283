<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container" style="position: relative;padding-right: 140px;">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey"  placeholder="活动名称"></el-input>
			</div>
			<!--优惠券类型  -->
			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="type" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in typeOptions" :key="item.value" :label="item.lable" :value="item.value"></el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addVoucher'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleTableEdit(1,0,1)" >添加</el-button>
				</button-permissions>
			</div>
			
			<div style="position: absolute;right: 20px;top: 0px;">
				<experience-code></experience-code>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table	:data="tableData"	style="width: 100%" v-loading="loading">
				<el-table-column	prop="Name"	label="活动名称"></el-table-column>
				<el-table-column	prop="UseTypeValue"	label="活动时间">
					<template slot-scope='scope'>
						<span>{{scope.row.StartTime}}</span>
						<span style='margin:0 5px;'>至</span>
						<span>{{scope.row.EndTime}}</span>
          </template>
				</el-table-column>
				<el-table-column  prop="ActivityStateShow" label="状态"></el-table-column>
				<el-table-column	prop="LuckyDrawCount"	label="抽奖人数"></el-table-column>
				<el-table-column	prop="WinnerCount"	label="中奖人数">
          <template slot-scope='scope'>
            <el-button v-if='scope.row.WinnerCount!=0' type='text' @click='popWinsDia(scope.row)'>{{scope.row.WinnerCount}}</el-button>
            <span v-else>0</span>
          </template>
        </el-table-column>
				<el-table-column	prop="AddTime"	label="创建时间"></el-table-column>
				<el-table-column	label="操作" width='180px'>
					<template slot-scope="scope" >
						<div v-if="scope.row.ActivityState==1">
							<button-permissions :datas="'editLottery'">
								<div class="table-button" @click="handleTableEdit(2,scope.row.Id,scope.row.ActivityState)">编辑</div>
							</button-permissions>

							<div class="table-button" @click="handleTablePromote(scope.row.Id)">推广</div>

							<buttonPermissions2 :datas="['finishLottery','copyLottery','lotteryPayRecord']">
								<el-dropdown>
									<el-button  type="text">
										更多
									</el-button>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item >
											<button-permissions :datas="'finishLottery'"  @isShow='isShowFinish'>
												<div class="table-button" @click="handleTableFail(scope.row.Id)">使终止</div>
											</button-permissions>
										</el-dropdown-item>

										<el-dropdown-item>
											<button-permissions :datas="'copyLottery'" @isShow='isShowCopy'>
												<div class="table-button" @click="handleTableEdit(4,scope.row.Id,1)">复制</div>
											</button-permissions>
										</el-dropdown-item>
										
									<el-dropdown-item >
										<button-permissions :datas="'lotteryPayRecord'">
											<div class="table-button"  @click="PayRecord(scope.row)">付费记录</div>
										</button-permissions>
									</el-dropdown-item>

									</el-dropdown-menu>
								</el-dropdown>
							</buttonPermissions2>
						</div>
						<div   v-else-if="scope.row.ActivityState==2" >
							<button-permissions :datas="'editLottery'">
								<div class="table-button" @click="handleTableEdit(2,scope.row.Id,scope.row.ActivityState)">编辑</div>
							</button-permissions>

							<div class="table-button" @click="handleTablePromote(scope.row.Id)">推广</div>


							<buttonPermissions2 :datas="['finishLottery','copyLottery','giftTimesBtn','lotteryPayRecord']">
								<el-dropdown>
									<el-button  type="text">
										更多
									</el-button>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item >
											<button-permissions :datas="'finishLottery'"  @isShow='isShowFinish'>
												<div class="table-button" @click="handleTableFail(scope.row.Id)">使终止</div>
											</button-permissions>
										</el-dropdown-item>

										<el-dropdown-item>
											<button-permissions :datas="'copyLottery'" @isShow='isShowCopy'>
												<div class="table-button" @click="handleTableEdit(4,scope.row.Id,1)">复制</div>
											</button-permissions>
										</el-dropdown-item>
									
										<el-dropdown-item>
											<button-permissions :datas="'giftTimesBtn'" >
												<div class="table-button"  @click="handleTableGift(scope.row.Id)">赠送次数</div>
											</button-permissions>
										</el-dropdown-item>
										
									<el-dropdown-item >
										<button-permissions :datas="'lotteryPayRecord'">
											<div class="table-button"  @click="PayRecord(scope.row)">付费记录</div>
										</button-permissions>
									</el-dropdown-item>

									</el-dropdown-menu>
								</el-dropdown>
							</buttonPermissions2>

						</div>

						<div v-else>

							<div class="table-button" @click="handleTableEdit(3,scope.row.Id,scope.row.ActivityState)">查看</div>

							<button-permissions :datas="'copyLottery'" @isShow='isShowCopy'>
								<div class="table-button" @click="handleTableEdit(4,scope.row.Id,1)">复制</div>
							</button-permissions>

							<buttonPermissions2 :datas="['restartLottery','lotteryPayRecord','deleteLottery']">
								<el-dropdown>
								<el-button  type="text">
									更多
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item >
										<button-permissions :datas="'restartLottery'">
											<div class="table-button"  @click="handleTableEdit(5,scope.row.Id,2)">重启</div>
										</button-permissions>
									</el-dropdown-item>
									
									<el-dropdown-item >
										<button-permissions :datas="'lotteryPayRecord'">
											<div class="table-button"  @click="PayRecord(scope.row)">付费记录</div>
										</button-permissions>
									</el-dropdown-item>
									
									<el-dropdown-item >
										<button-permissions :datas="'deleteLottery'" >
											<div class="table-button" style="color:#F56C6C"  @click="handleTableDelete(scope.row.Id)">删除</div>
										</button-permissions>
									</el-dropdown-item>

								</el-dropdown-menu>
							</el-dropdown>
							</buttonPermissions2>

						</div>

					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>

		<!-- <el-dialog title="中奖明细" :visible.sync="winnersListShow"  v-if="winnersListShow" width="800px" style='overflow:hidden;' class="dialog data-dialog">
				<div style='width:100%;overflow:hidden;'>
					<div style='margin-bottom:20px;'>
						<span style='margin-right:50px;'>中奖人数：{{currentWins.Number}}人</span>
						<span>已发放奖品：{{currentWins.Count}}</span>
					</div>
					<el-table	:data="winnersList"	style="width: 100%;" v-loading="winsLoading" max-height='500px'>
						<el-table-column	prop="MemberName"	label="客户">
							<template slot-scope='scope'>
								<div style='width:200px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;'>
									{{scope.row.MemberName}}
								</div>
							</template>
						</el-table-column>
						<el-table-column	prop="PrizeTypeName"	label="奖品类型"></el-table-column>
						<el-table-column	prop="PrizeName"	label="奖品"></el-table-column>
						<el-table-column	prop="AddTime"	label="中奖时间"></el-table-column>
					</el-table>
					<el-pagination v-if="winsPage.total" style="margin-top:20px;float:right;" @size-change="handleWinsSizeChange" @current-change="handleWinsCurrentChange"
						:current-page="winsPage.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="winsPage.size" layout="total, sizes, prev, pager, next, jumper"
						:total="winsPage.total">
					</el-pagination>
				</div>
		</el-dialog> -->

		<!-- 推广的弹框 -->
		<batch-cods  :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData"  @cancel="weiCodeCancel"></batch-cods>

		<el-dialog title="中奖明细" :visible.sync="winnersListShow" width="1100px" style='overflow:hidden;' v-loading="winnersListLoading"
		 class="dialog data-dialog" @close="closeDetail">
				<div style='width:100%;overflow:hidden;'>
					<div style='margin-bottom:20px;'>
						<span style='margin-right:50px;'>中奖人数：{{currentWins.Number}}人</span>
						<span>已发放奖品：{{currentWins.Count}}</span>
					</div>
					<div class = "flexRow" style="margin:10px 0px;flex-wrap:wrap;">
						<div class = "flexRow" style="margin:0px 10px 10px 0px">
							<div class ="keyFont">关键字：</div>
							<el-input v-model="detailKeyWords" placeholder="微信昵称、手机号" style="width:180px"></el-input>
						</div>
						<div class = "flexRow" style="margin:0px 50px 10px 0px">
							<div class ="keyFont">奖品类型：</div>
							<el-select v-model="detailType" style="width:180px">
								<el-option v-for="(v,i) in detailTypeList" :key="i" :value="v.value" :label="v.label"></el-option>
							</el-select>
						</div>
						<div class = "flexRow" style="margin:0px 130px 10px 0px">
							<div class ="keyFont">发放状态：</div>
							<el-select v-model="detailState" style="width:100px">
								<el-option v-for="(v,i) in detailStateList" :key="i" :value="v.value" :label="v.label"></el-option>
							</el-select>
						</div>
						<el-button type="primary" style="margin-bottom:10px" @click="winsPage.current=1,getWinsList()">查询</el-button>
					</div>
					<el-table	:data="winnersList"	style="width: 100%;" v-loading="winsLoading" max-height='500px'>
						<el-table-column	prop="MemberName"	label="客户" width="200">
							<template slot-scope='scope'>
								<div class="flexRow">
									<img :src="scope.row.MemberWxHeadUrl?scope.row.MemberWxHeadUrl:defaultheadImg" style="width:40px;height:40px;border-radius:3px" alt="">
									<div style="margin-left:10px">
										<div class ="fonthidden" style='width:150px'>
											{{scope.row.MemberName}}
										</div>
										<div>{{scope.row.MemberPhone}}</div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column	prop="PrizeTypeName"	label="奖品类型"></el-table-column>
						<el-table-column	prop="PrizeName"	label="奖品" width="200">
							<template slot-scope="scope">
								<div>
									<div class ="fonthidden">{{scope.row.PrizeName}}</div>
									<div style="color:#f56c6c" v-if="scope.row.PrizeTypeName=='现金红包'">(金额：{{scope.row.CashRedEnvelope}}元)</div>
									<div style="color:#f56c6c" v-if="scope.row.PrizeTypeName=='下单折扣'">(折扣：{{scope.row.OrderDiscount}}折)</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column	prop="UseTime"	label="中奖时间" width="150"></el-table-column>
						<el-table-column	prop="AddTime"	label="发放状态" width="120">
							<template slot-scope="scope">
								{{scope.row.IsSend?'已发放':'待核销发放'}}
							</template>
						</el-table-column>
						<el-table-column	prop="SendTime"	label="发放时间" width="150"></el-table-column>
						<el-table-column	prop=""	label="操作" width="80">
							<template slot-scope="scope">
								<buttonPermissions :datas="'lotteryAuditIssue'">
									<el-button type="text" v-if="!scope.row.IsSend&&(scope.row.PrizeTypeName=='现金红包'||scope.row.PrizeTypeName=='下单折扣')"
									 @click="AuditIssue(scope.row)">核销发放</el-button>
								</buttonPermissions>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination v-if="winsPage.total" style="margin-top:20px;float:right;" @size-change="handleWinsSizeChange" @current-change="handleWinsCurrentChange"
						:current-page="winsPage.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="winsPage.size" layout="total, sizes, prev, pager, next, jumper"
						:total="winsPage.total">
					</el-pagination>
				</div>
		</el-dialog>

		<el-dialog title="赠送抽奖次数" :visible.sync="giftTimesVisible"  v-if="giftTimesVisible" width="700px" style='overflow:hidden;' class="dialog data-dialog gift-times">
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="giftTimesData.phone"  placeholder="手机号" maxlength="11"></el-input>
				<el-button type="primary" style="margin-left:10px;width:100px;" @click="handleSearchCustomer" >查询</el-button>
			</div>
			<p style="color:#909399;" v-if="!giftTimesData.customerList.length">输入客户手机号，查找客户消息并赠送抽奖次数；可查找多个客户批量赠送</p>
			<div v-else class="customer-list">
				<div v-for="(item,index) in giftTimesData.customerList" class="item" :key="index">
					<i class="el-icon-error" @click="deleteGiftTimesItem(index)"></i>
					<img :src='item.WxHeadUrl'>
					<div>{{item.Phone}}</div>
				</div>
			</div>
			<div class="gift-count">
				<label class="label">赠送次数/人: </label>
				<el-input v-model="giftTimesData.count"  @input='giftTimesData.count=validateInt(giftTimesData.count)'  placeholder="请输入赠送次数/人"></el-input>
			</div>
			<div slot="footer" style="text-align:center">
				<el-button @click="closeGiftTimes" style="width:150px">关闭</el-button>
				<el-button @click="handleGiftTimes" type="primary" style="width:150px">确认赠送</el-button>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="recordShow" title="付费购买记录" width="800px">
			<div class="flex flex-align-center">
				<div class="flex flex-align-center margin-right-10">
					<div class="keyLabel">关键字：</div>
					<el-input style="width:180px" placeholder="微信昵称、手机号" v-model.trim="recordkeywords"></el-input>
				</div>
				<el-button type="primary" @click="queryRecord">查询</el-button>
			</div>
			<el-table :data="recordlist" v-loading="recordloading" max-height="500" class="margin-top-20">
				<el-table-column prop="" label="客户" min-width="200">
					<template slot-scope="scope">
						<div>
							<div class="ellipsis">{{scope.row.WxNickname}}</div>
							<div>{{scope.row.Phone}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="PayMoney" label="付费金额(元)"></el-table-column>
				<el-table-column prop="SendWheelCount" label="购买次数(次)"></el-table-column>
				<el-table-column prop="PayTime" label="购买时间"></el-table-column>
			</el-table>
			<div class="margin-top-10" style="text-align:right" v-if="recordtotal">
        <el-pagination
          @size-change="recordSizeChange"
          @current-change="recordCurrentChange"
          :current-page="recordcurrentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="recordpagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="recordtotal">
        </el-pagination>
      </div>
		</el-dialog>

	</div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions'
import buttonPermissions2 from '@/components/buttonPermissions2'
import batchCods from '@/components/bathCodes'
import apiList	from '@/api/other'
import {surePhone} from '@/filters/index';
import config from "@/config"

import experienceCode from '@/components/experienceCode';

	export default {
		components: {
			buttonPermissions,batchCods,
			experienceCode,
			buttonPermissions2
		},
		data(){
			return{
				defaultheadImg:config.DEFAULT_HEADER,
				weiCodeData:{},
				isMoreShow:false,
				weiCodeShow:false,
				bathSortDatas: [],
				searchKey:'',
				type:null,
				loading: false,
				typeOptions:[
					{
						lable:'未开始',value:0
					},
					{
						lable:'进行中',value:1
					},
					{
						lable:'已结束',value:2,
					}
				],
				tableData:[
				],
				page:{
					total:0,
					current:1,
					size:20
				},
				currentEditId:null,
				winsLoading:false,
				winnersList:[],
				winnersListShow:false,
				winnersListLoading:false,
				currentWins:{
					Id:0,
					Number:0,
					Count:0
				},
				winsPage:{
					total:0,
					current:1,
					size:10
				},
				showCopyBtn:true,
				showFinishBtn:true,

				giftTimesVisible: false,
				giftTimesData: {
					activityId: null,
					phone: '',
					customerList: [],
					count: null
				},

				detailKeyWords:'',
				detailType:null,
				detailTypeList:[
					{value:null,label:'全部'},
					{value:1,label:'积分'},
					{value:2,label:'优惠券'},
					{value:3,label:'赠品'},
					{value:7,label:'现金红包'},
					{value:8,label:'下单折扣'},
				],
				detailState:null,
				detailStateList:[
					{value:null,label:'全部'},
					{value:false,label:'待核销发放'},
					{value:true,label:'已发放'},
				],
				rowmsg:{},
				recordShow:false,
				recordkeywords:'',
				recordlist:[],
				recordloading:false,
				recordcurrentPage:1,
				recordpagesize:10,
				recordtotal:0,
			}
		},
		created(){
			this.getList()
		},
    methods: {
			recordSizeChange(e){
				this.recordcurrentPage = 1
				this.recordpagesize = e
				this.getrecordlist()
			},
			recordCurrentChange(e){
				this.recordcurrentPage = e
				this.getrecordlist()
			},
			PayRecord(row){
				this.rowmsg = row
				this.recordkeywords = ''
				this.recordlist = []
				this.recordShow = true
				this.queryRecord()
			},
			queryRecord(){
				this.recordcurrentPage = 1
				this.getrecordlist()
			},
			async getrecordlist(){
				try{
					this.recordloading = true

					let res = await apiList.activityWheelSurfpayRecord({
						Id:this.rowmsg.Id,
						Keywords:this.recordkeywords,
						Skip:(this.recordcurrentPage-1)*this.recordpagesize,
          	Take:this.recordpagesize
					})
					if(res.IsSuccess){
						this.recordlist = res.Result.Results
          	this.recordtotal = res.Result.Total
					}
				}finally{
					this.recordloading = false
				}
			},
			closeDetail(){
				this.winsPage = {
					current:1,
					size:10,
					total:0,
				}
				this.detailKeyWords = ''
				this.detailType = null
				this.detailState = null
				this.winnersListShow = false
			},
			AuditIssue(row){
				this.$confirm('是否确认核销发放？核销成功后不可撤消。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.confirmAuditIssue(row)
        }).catch(() => {});
			},
			async confirmAuditIssue(row){
				this.winsLoading = true
				try{
					console.log(row)
					let data = {
						Id:await row.Id
					}
					let res = await apiList.activityWheelSurfwriteOff(data)
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.getWinsList()
					}
				}finally{
					this.winsLoading = false
				}
			},
			// 验证整数
			validateInt(num){
				num = num.replace(/^0*(0\.|[1-9])/, '$1');
				num = num.replace(/[^\d]/g, "");
				if(num>10||num<1){
					this.$message({
						type:'error',
						message: '赠送次数请输入在1次至10次之间，整数',
						showClose: true
					})
					num= null
				}
				this.$forceUpdate();
				return  num
			},
			// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						KeyWord :this.searchKey,
						ActivityState : this.type,
						Skip: (this.page.current - 1) * this.page.size, 
						Take: this.page.size, 
					}
					let result = await apiList.activityWheelSurfList(data);
					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results || [];

					this.tableData.forEach((t)=>{
						t.ActivityState=t.ActivityStateShow=='未开始'?1:(t.ActivityStateShow=='进行中'?2:3)
					})
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
						this.loading = false;
				}
			},
			handleFilter(){
				this.page.size = 20
				this.page.current = 1
				this.getList()
			},
			//type---1:新增;2:编辑;3:查看;4:复制 5重启
			//status---1:未开始;2:进行中;3:已结束
			handleTableEdit(type,id,status){
				this.$router.push({ 
          path: '/market/lottery/editlotteryDetail',
          query:{
						type:type,
						id:id,
						status:status
          }
        })
			},
			//使失效
			handleTableFail(id){
				this.$confirm('活动终止后，你将不可再对这场活动进行编辑，是否确认使终止？', '提示', {
					confirmButtonText: '确认终止',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					let result = await apiList.activityWheelSurfOpen({
						Id: id
					})
					this.$message({showClose: true,
						type: 'success',
						message: '终止成功!'
					});
					this.getList()

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消终止'
					});
				}).finally(() => {
					// this.getList()
				})
			},
				// table删除
			handleTableDelete(id){
				this.$confirm('是否确认删除本场活动?删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					try{
						let result = await apiList.activityWheelSurfDel({
							Id: id
						})
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});

						let currentPageTotal = this.page.total - (this.page.current - 1) * this.page.size
						if(currentPageTotal == 1 && this.page.current!=1){
							this.page.current -= 1
						}
						this.getList()

					} catch(e) {
						console.log("e",e)
						return 
					} finally{

					}

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					// this.getList();
				})
			},
			handleTableGift(id){
				this.giftTimesData = {
					activityId : id,
					phone: '',
					customerList: [],
					count: null
				}
				this.giftTimesVisible = true
			},
			getRamNumber(){
				var result='';
				for(var i=0;i<6;i++){
				
					result+=Math.floor(Math.random()*16).toString(16);
				}
				
				return result;
			},
			// 推广-优惠券
			handleTablePromote(id){
				this.weiCodeShow = true;
				let uid =this.getRamNumber()
				this.weiCodeData = {
					Page:'pageA/pages/activity/truntable/truntable',
					Scene:`id=${id}`
				}
			},
			weiCodeCancel(val) {
				this.weiCodeShow = false;
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
			async getWinsList(){
				this.winsLoading = true;
				try {
					let result = await apiList.activityWheelSurfDetail(
						{
							Id:this.currentWins.Id,
							KeyWord:this.detailKeyWords,
							PrizeType:this.detailType,
							IsSend:this.detailState,
							Skip: (this.winsPage.current - 1) * this.winsPage.size, 
							Take: this.winsPage.size, 
						}
					);
					this.winsPage.total = result.Result.Total;
					this.winnersList = result.Result.Results || [];
				} catch (error) {
					console.log(error);
					this.winsLoading = false
				} finally {
					this.winsLoading = false;
				}
			},
			popWinsDia(val){
				this.currentWins = {
					Id:val.Id,
					Number:val.WinnerCount,
					Count:val.WinningNumber
				}
				this.winnersListShow=true
				this.getWinsList()
			},
			// 切换显示条数
			handleWinsSizeChange(val) {
				this.winsPage.size = val;
				this.getWinsList();
			},
			// 翻页
			handleWinsCurrentChange(val) {
				this.winsPage.current = val;
				this.getWinsList();
			},
			isShowCopy(val){
				this.showCopyBtn = val
			},
			isShowFinish(val){
				this.showFinishBtn = val
			},
			async handleSearchCustomer(){
				if(!this.giftTimesData.phone){
					this.$message({
						type:'error',
						message: '请输入客户手机号',
						showClose: true
					})
					return 
				}

				if(!surePhone(this.giftTimesData.phone)){
					this.$message({
						type:'error',
						message: '请输入客户完整的11位手机号',
						showClose: true
					})
					return 
				}
				try{
					let result = await apiList.activityWheelSurfSearchMember(
						{
							Phone: this.giftTimesData.phone
						}
					)

					let flag = this.giftTimesData.customerList.filter(t=> {
						return t.Phone== result.Result.Phone
					})
					if(flag.length){
						this.$message({
							type:'error',
							message: `${this.giftTimesData.phone}手机号已存在`,
							showClose: true
						})
						return 
					}
					this.giftTimesData.customerList.push(result.Result)
				} catch(e){

				} finally{

				}
			},
			deleteGiftTimesItem(index){
				this.giftTimesData.customerList.splice(index,1)
			},
			closeGiftTimes(){
				this.giftTimesVisible= false
			},
			handleGiftTimes(){
				if(!this.giftTimesData.customerList.length&&this.giftTimesData.phone){
					this.$message({
						type:'error',
						message: '请点击[查询]，查询客户信息',
						showClose: true
					})
					return 
				}
				if(!this.giftTimesData.customerList.length){
					this.$message({
						type:'error',
						message: '请先查询客户信息',
						showClose: true
					})
					return 
				}
				if(!this.giftTimesData.count){
					this.$message({
						type:'error',
						message: '请输入赠送次数',
						showClose: true
					})
					return 
				}
				this.giftTimesVisible= false
					
				this.confirmGiftTimes()
			},
			confirmGiftTimes(){

				this.$confirm('抽奖次数赠送成功后，不可撤消或收回，请确认是否继续赠送？', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        }).then(async() => {
					try{
						let idlist = []
						this.giftTimesData.customerList.forEach(t=> {
							idlist.push(t.MemberId)
						})
						let data = {
							ActivityFullId: this.giftTimesData.activityId,
							MemberIdList: idlist,
							SendNumber: this.giftTimesData.count,
						}
						let result = await apiList.activityWheelSurfSendNumber(data)
						if(result.IsSuccess){
							this.$message({
								type: 'success',
								message: '赠送成功',
								showClose: true
							});   
						}
					} catch(e){

					} finally{

					}
        }).catch(() => {
          this.$message({
            type: 'info',
						message: '已取消赠送',
						showClose: true
          });          
        });

			}
    }
	}
</script>

<style  lang="less" scoped>
.fonthidden{
	text-overflow:ellipsis;white-space:nowrap;overflow:hidden;
}
.flexRow{
	display: flex;
	align-items: center;
}
.keyFont{
	font-size: 14px;
	color:#606266;
	font-weight: bold;
	white-space: nowrap;
}
.app-container{
	::v-deep .el-input{
		width: 220px;
	}
	background-color: #fff;
}
	.table-button{
		color: #409EFF;
		display: inline-block;
		margin-right: 15px;
		cursor: pointer;
	}
	.dialog{
		width: 100%;
		::v-deep .el-dialog__header {
				border-bottom: 1px solid #ddd;
		}
		.content{
			padding: 0 30px;
			display: flex;
			flex-direction: row;
			.right{
				margin-left: 20px;
			}
			.text{
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}
		.button{
			margin: 0 auto;
			width: 200px;
		}
	}
	.data-dialog{
		.value{
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}
		.item{
			color: #666;
			margin-top: 10px;
		}
		overflow:hidden;
	}
	.dialog-footer{
		margin-top:30px;
		text-align: center;
	}
	.el-pagination{
		::v-deep .el-input{
			width:100px;
		}
	}
	.gift-times{
		.customer-list{
			max-height: 350px;
			overflow: auto;
			.item{
				.el-icon-error{
					position: absolute;
					margin-left: 70px;
					margin-top: -5px;
					font-size: 16px;
					cursor: pointer;
				}
				display: inline-block;
				width: 130px;
				text-align: center;
				margin-top: 20px;
				img{
					width: 80px;height: 80px;
					object-fit: contain;
					border: 1px solid #ddd;
					margin-bottom: 5px;
				}
			}
		}
		.gift-count{
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid #ddd;
		}
	}
</style>
